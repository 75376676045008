<template>
  <h1 class="text-h1">
    <hero></hero>
    <div class="pa-16 text-h3">Em breve...</div>
  </h1>
</template>

<script>
import Hero from '@/components/apps/Hero.vue'

export default {
  components: {
    Hero,
  },
}
</script>
